import React from 'react'
import { LogoElement } from '../styles/GlobalStyles'
import LogoGraphic from '../images/logo.svg'

const Logo = ({color}) => {
    return (
        <LogoElement to="/" aria-label="Link to homepage">
            <img src={LogoGraphic} alt="Craig Charles Law Logo" />
        </LogoElement>
    )
}

export default Logo
