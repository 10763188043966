import styled, { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import { Link } from 'gatsby'
import { defaultTheme } from './ThemeStyles'

export const GlobalStyles = createGlobalStyle`
    ${normalize}
    
    html {
        box-sizing: border-box;
    }

    body {
        background: #dbdee1;
        overflow-x: hidden;
    }

    *, *:before, *:after {
        box-sizing: inherit;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        padding: 0;
    }

    &::selection {
        background: ${defaultTheme.green};
        color: ${defaultTheme.darkBlue}
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    ol {
        padding-left: 15px;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    img {
        height: auto;
        width: 100%;
    }

    .centered {
        text-align: center;
    }

    blockquote {
        margin: 20px 0 20px 32px;
        font-family: georgia;
    }

`

export const LogoElement = styled(Link)`
    position: relative;
    display: inline-block;
    width: 100px;
    z-index: 2;
    /* filter: brightness(100); */

    @media(min-width: 900px) {
        width: 120px;
    }
`;

export const Table = styled.table`
    margin: 0 0 24px;
    width: 100%;
    border-collapse: collapse;

    @media (min-width: 900px) {
        margin-bottom: 56px;
    }

    thead {
        background: ${defaultTheme.darkBlue};
        color: ${defaultTheme.white};

        th {
            padding: 16px 12px;
            font-size: 24px;
            letter-spacing: 0.031em;
            line-height: 1.25;

            @media (min-width: 900px) {
                padding: 24px 0;
            }
        }
    }

    tbody {
        tr {
            &:nth-of-type(even) {
                background: ${defaultTheme.white};
            }
        }
        td {
            display: block;
            padding: 16px 12px;
            width: 100%;
            border-left: 1px solid ${defaultTheme.darkBlue};
            border-right: 1px solid ${defaultTheme.darkBlue};
            border-bottom: 1px solid ${defaultTheme.darkBlue};
            line-height: 1.5;


            @media (min-width: 900px) {
                display: table-cell;
                border: 1px solid ${defaultTheme.darkBlue};
                
                &:first-child {
                    width: 35%;
                }

                &:last-child {
                    width: 65%;
                }
            }
        }
    }
`