import styled from 'styled-components'
import { defaultTheme } from '../../styles'

export const FooterElement = styled.footer`
    padding: 40px 0 80px;    
    background: #dbdee1;

    @media(min-width: 900px) {
        padding: 80px 0 16px;
    }

    li {
        color: ${defaultTheme.darkBlue};
        letter-spacing: 0.031em;
        line-height: 1.618;
    }

    .col {
        ul {
            margin-bottom: 24px;
            padding-right: 8px;
            
            li {
                margin-bottom: 10px;
                font-size: 15px;
                font-weight: 300;
                
                &:first-child {
                    font-size: 14px;
                    font-weight: 700;
                    letter-spacing: 0.031em;
                    text-transform: uppercase;
                }
            }

            a {
                color: inherit;
            }
        }
    }

    @media(hover: hover) and (pointer: fine) {
        a:hover {
            border-bottom: 1px solid ${defaultTheme.darkBlue};
        }
    }
`;

export const FooterLegal = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 32px;

    @media(min-width: 600px) {
        flex-direction: row;
        justify-content: space-between;
    }

    @media(min-width: 900px) {
        padding-top: 40px;
    }
   
    img {
        max-width: 120px;

        @media(min-width: 600px) {
            max-width: 150px;
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        margin: 24px 0;

        @media(min-width: 600px) {
            flex-direction: row;
        }

        li {
            padding-right: 12px;
            font-size: 14px;
            font-weight: 300;

            @media(min-width: 600px) {
                padding-left: 16px;
                padding-right: 0;
            }
        }
    }
`;
