import styled from 'styled-components'
import { Link } from 'gatsby'
import { defaultTheme } from '../../styles/ThemeStyles'
import { motion } from 'framer-motion'

export const HeaderMain = styled.header`
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px 0;
    width: 100%;
    z-index: 10;

    .slidingborder {
        position: absolute;
        bottom: 12px;
        height: 3px;
        width: 0;
        background: ${defaultTheme.green};
        transition: ease-in-out 0.25s;
    }
`;

export const HeaderNav = styled(motion.ul)`
    @media(max-width: 990px) {
        display: ${({ nav }) => (nav ? "flex" : "none") };
        position: absolute;
        flex-direction: column;
        left: 0;
        top: 0;
        height: ${({ nav }) => (nav ? "100vh" : "0") };
        padding: 120px 0 0;
        width: 100vw;
        background: ${defaultTheme.darkBlue};
        opacity: 0;
    }

    @media(min-width: 990px) {
        display: flex !important;
        position: relative;
        flex-direction: row;
        height: auto !important;
        opacity: 1 !important;
    }
`;

export const SlidingBorder = styled.li``;

export const DropDownToggle = styled.button`
    position: absolute;
    right: 0;
    top: 16px;
    width: 8%;
    background: none;
    border: none;
    color: ${defaultTheme.green};
    font-size: 32px;
    z-index: 2;

    @media (min-width: 990px) {
        display: none;
    }
`

export const HeaderSubMenu = styled(motion.ul)`
    display: none;

    @media(max-width: 990px) {
        max-height: 250px;
        padding-left: 16px;
        overflow: scroll;
    }

    @media(min-width: 990px) {
        position: absolute;
        top: 100%;
        margin-top: -12px;
        min-width: 300px;
        background: #FFF;
    }

    li {
        a {
            @media(min-width: 990px) {
                display: block;
                padding: 16px 10px;
                color: ${defaultTheme.darkBlue};
                line-height: 1.618;
                @media(hover: hover) and (pointer: fine) {
                    &:hover {
                        background: ${defaultTheme.green};
                        color: ${defaultTheme.darkBlue};
                    }
                }
            }
        }
    }

    &.active {
        display: block;
    }
`;

export const HeaderLinkParent = styled(motion.li)`
    position: relative;
    margin: 0 20px;
    border-bottom: 1px solid #233956;
    transition: ease 0.25s;

    &:after {
        position: absolute;
        bottom: 0;
        left: 50%;
        height: 2px;
        width: 0;
        background: ${defaultTheme.green};
        content: "";
        transition: inherit;
    }

    @media(hover: hover) and (pointer: fine) {
        &:hover {
            ${HeaderSubMenu} {
                display: block;
            }
        }
    }

    @media(min-width: 900px) {
        border-bottom: none;
        opacity: 1 !important;
        transform: none !important;
    }
`;

export const HeaderLink = styled(motion(Link))`
    position: relative;
    display: inline-block;
    padding:  20px 0;
    width: 92%;
    color: ${defaultTheme.white};
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.031em;
    transition: ease 0.25s;
    transform: translateX(0);

    @media(min-width: 990px) {
        display: inline-block;
        padding:  28px 0px 26px;
        width: auto;
    }

    @media(hover: hover) and (pointer: fine) {
        &:hover {
            color: ${defaultTheme.grey};
        }
    }

    &.header--active {
        position: relative;
        color: ${defaultTheme.green};

        @media(min-width: 990px) {
            &:after {
                position: absolute;
                left: 0;
                bottom: 12px;
                height: 2px;
                width: 100%;
                background: ${defaultTheme.green};
                content: "";
                transition: left ease 1s;
            }
        }
    }

    
        &.header--active--sub {
            color: ${defaultTheme.green};
            @media(hover: hover) and (pointer: fine) {
                background: ${defaultTheme.green};
                color: ${defaultTheme.darkBlue};
            }
        }
`;

export const MenuButton = styled.button`
    position: absolute;
    right: 12px;
    display: flex;
    background: none;
    border: none;
    color: ${defaultTheme.green};
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0.06em;
    text-transform: uppercase;

    @media(min-width: 990px) {
        display: none;
    }

    div {
        position: relative;
        top: 3px;
        display: block;
        margin-left: 12px;
        width: 32px;
        height: 30px;
        transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);

        span {
            position: absolute;
            display: block;
            height: 1px;
            width: 100%;
            background: ${defaultTheme.green};

            &:first-child {
                top: ${({ nav }) => (nav ? "9px" : "0") };
                transform: rotate(${({ nav }) => (nav ? "45deg" : "0") });
            }

            &:nth-child(2n) {
                top: 9px;
                opacity: ${({ nav }) => (nav ? "0" : "1") };
            }

            &:last-child {
                top: ${({ nav }) => (nav ? "9px" : "18px") };
                transform: rotate(${({ nav }) => (nav ? "-45deg" : "0") });
            }
        }
    }
`;



