import React from 'react'
import {
    ContactFormElement
} from './ContactFormStyles'
import { FormButton } from '../../styles'

const ContactForm = () => {
    return (
        <ContactFormElement>
            <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                <p>
                    <label>Name: <input type="text" name="name" /></label>   
                </p>
                <p>
                    <label>Email address: <input type="email" name="email" /></label>   
                </p>
                <p>
                    <label>Phone Number: <input type="tel" name="phone" /></label>   
                </p>
                <p>
                    <label>Case Details: <textarea name="casedetails"></textarea></label>
                </p>
                <p>
                    <FormButton type="submit" modifiers={['large', 'green']}>
                        Submit Inquiry
                    </FormButton>  
                </p>
            </form>
        </ContactFormElement>
    )
}

export default ContactForm
