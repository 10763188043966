import React, { useState, useRef } from 'react'
import uniqueId from 'uniqid'
import { Container } from '../../styles/LayoutStyles'
import Logo from '../Logo'
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai'
import {
    HeaderMain,
    HeaderNav,
    HeaderLinkParent,
    HeaderLink,
    HeaderSubMenu,
    MenuButton,
    DropDownToggle,
} from './HeaderStyles'
import HeaderSubMenuElement from './HeaderSubMenuElement'

const Header = ({props}) => {

    const Links = [
        {
            label: "Practice Areas",
            linkUrl: "/child-sexual-abuse/",
            className: "parent",
            subMenu: [
                {
                    label: "Child Sex Abuse",
                    linkUrl: "/child-sex-abuse-lawyer",
                },
                {
                    label: "Boyscouts Sex Abuse",
                    linkUrl: "/child-sex-abuse/boy-scouts-child-sexual-abuse-lawyer/",
                },
                {
                    label: "Clergy Sex Abuse",
                    linkUrl: "/child-sex-abuse/clergy-sexual-abuse-lawyer/",
                },
                {
                    label: "College Sex Abuse",
                    linkUrl: "/child-sexual-abuse/college-sexual-assault-and-harassment-title-nine/",
                },
                {
                    label: "Doctor Sex Abuse",
                    linkUrl: "/child-sexual-abuse/doctor-therapist-sexual-abuse/",
                },
                {
                    label: "Foster Care Sex Abuse",
                    linkUrl: "/child-sexual-abuse/foster-care-sexual-abuse/",
                },
                {
                    label: "LGBT Sex Abuse and Sexual Harassment",
                    linkUrl: "/lgbt-victims-of-sex-abuse-lawyer/",
                },
                {
                    label: "Sports Sex Abuse",
                    linkUrl: "/child-sex-abuse/sexual-abuse-in-sports/",
                },
                {
                    label: "School Sex Abuse",
                    linkUrl: "/child-sex-abuse/sexual-abuse-in-school/",
                },
                {
                    label: "Transgender College Sexual Harassment",
                    linkUrl: "/child-sex-abuse/transgender-college-sexual-harassment/",
                }
            ]
        },
        {
            label: "Firm Overview",
            linkUrl: "/about/",
        },
        {
            label: "Client Reviews",
            linkUrl: "/reviews/",
        },
        {
            label: "Results",
            linkUrl: "/results/",
        },
        {
            label: "Resources",
            linkUrl: "/resources/",
            className: "parent",
            subMenu: [
                {
                    label: "Sexual Abuse Blog",
                    linkUrl: "/sexual-abuse-blog/",
                },
            ],
        },
        {
            label: "Contact",
            linkUrl: "/contact-us/"
        },
    ]

    const transition = {
        duration: 0.25,
        ease: [0.43, 0.13, 0.23, 0.96]
    }

    const container = {
        hidden: { opacity: 0, height: "0" },
        show: {
            opacity: 1,
            height: "100vh",
            transition: {
                staggerChildren: 0.15,
                ...transition
            }
        }
    }
    
    const item = {
        hidden: {
            y: 5,
            opacity: 0,
        },
        show: {
            y: 0,
            opacity: 1,
            transition: {
                y: { stiffness: 1000, velocity: -100 }
              }
        }
    }

    const [nav, showNav] = useState(false);
    const [childMenuToggle, showChildMenuToggle] = useState(null);
    const containerRef = useRef(null);

    const toggleDD = id => {
        showChildMenuToggle((childMenuToggle) => (childMenuToggle === id ? null : id))
    }

    return (  
        <>
            <HeaderMain>
                <Container flex alignCenter spaceBetween>
                    <Logo />
                    <HeaderNav
                        nav={nav}
                        initial={false}
                        animate={nav ? "show" : "hidden"}
                        ref={containerRef}
                        variants={container}
                    >
                        {Links.map((link, index) => {
                            return <HeaderLinkParent key={link.linkUrl} variants={item}>
                                    <HeaderLink
                                        to={link.linkUrl}
                                        activeClassName={`header--active`}
                                        partiallyActive={true}
                                    >
                                        {link.label}
                                    </HeaderLink>
                                {link.subMenu && link.subMenu.length > 0 ? (
                                    <>
                                        <DropDownToggle
                                            onClick={() => toggleDD(index)}
                                            id={index}
                                        >
                                            {childMenuToggle === index ? (
                                                <AiFillMinusCircle />
                                            ) : (
                                                <AiFillPlusCircle />
                                            )}
                                        </DropDownToggle>
                                        <HeaderSubMenu
                                            className={`${childMenuToggle === index ? 'active' : ''}`}
                                            id={index}
                                            key={uniqueId('cc')}
                                        >
                                            {link.subMenu.map((subLink) => (
                                                <HeaderSubMenuElement
                                                    key={uniqueId('cc')}
                                                    label={subLink.label}
                                                    menuId={index}
                                                    linkUrl={subLink.linkUrl}
                                                />
                                            ))}
                                        </HeaderSubMenu>
                                    </>
                                ) : null}
                            </HeaderLinkParent>
                        })}
                    </HeaderNav>
                    <MenuButton nav={nav} onClick={() => showNav(!nav)}>
                        Menu
                        <div>
                            <span />
                            <span />
                            <span />
                        </div>
                    </MenuButton>
                </Container>
            </HeaderMain>
        </>
    )
}

export default Header
