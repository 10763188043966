import React from 'react'
import { HeaderLink } from './HeaderStyles'
const HeaderSubMenuElement = ({
    label,
    linkUrl,
}) => {
    return (
        <>
            <li>
                <HeaderLink
                    to={linkUrl}
                    activeClassName={`header--active--sub`}
                    partiallyActive={true}
                >
                    {label}
                </HeaderLink>
            </li>
        </>
    )
}

export default HeaderSubMenuElement
